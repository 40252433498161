import {useEffect, useState} from 'react';
import {Auth, Hub} from '@ametektci/ametek.stcappscommon';
import {AmetekAuthentication} from "@ametektci/ametek.stcappscommon";
import {UpdateUrlParams} from "./utils/WindowUtils";
import App from "./App";
import {HelmetProvider} from 'react-helmet-async';
import {OrganizationContextWrapper} from "./contexts/OrganizationContext";
import {ApiContextWrapper} from "./contexts/APIContext";
import {LanguageContextWrapper} from "./contexts/LanguageContext";

export default function AppWithAuth() {
    //States:
    const [loggedIn, setLoggedIn] = useState(false)
    //effects:
    useEffect(() => {
        Hub.listen("auth", (data) => getLogInState(data.payload.event))
        getLogInState("login")
    }, [])

    const getLogInState = (state: string) => {
        if (["login", "signIn", "signOut", "signUp", "verify"].includes(state))
            Auth.currentAuthenticatedUser().then(() => {
                setLoggedIn(true)
            }).catch(() => {
                setLoggedIn(false)
            })
    }
    // default state should be sign in page
    let authState: "signIn" | "signUp" | "resetPassword" = 'signIn';
    // unless the sign up search parameter has been added -- this indicates a redirection from an app
    if (new URL(window.location.href).searchParams.has('signUp')) {
        authState = 'signUp';
        // remove sign up search param
        UpdateUrlParams('');
    }

    if (!loggedIn)
        return (
            <AmetekAuthentication page={authState} forceOpen={true}/>
        );
    else
        return (
            <LanguageContextWrapper>
                <ApiContextWrapper>
                    <HelmetProvider>
                        <OrganizationContextWrapper>
                            <App/>
                        </OrganizationContextWrapper>
                    </HelmetProvider>
                </ApiContextWrapper>
            </LanguageContextWrapper>
        )

}