import {Image, Menu, Container, Dropdown, Icon} from 'semantic-ui-react';
import ametekLogo from '../images/AmetekSTC.svg';
import {Auth, authenticationStore} from '@ametektci/ametek.stcappscommon';
import {textResources} from "../language/TextResources";
import LanguageSelection from "../language/LanguageSelection";
import {useContext, useEffect, useState} from "react";
import {OrganizationContext} from "../contexts/OrganizationContext";
import {ConnectionInfo} from "./ConnectionInfo";
import {useNavigate} from "react-router-dom";

export default function AppHeader() {
    const orgContext = useContext(OrganizationContext)
    const [username, setUsername] = useState("")
    const navigate = useNavigate()
    useEffect(() => {
        Auth.currentAuthenticatedUser().then(u => setUsername(u?.attributes?.name ?? ""))
    },[])
        let name = username;
        if (orgContext.user) {
            name = orgContext.user.name;
        }

        return (
            <Menu fixed='top' inverted size='large'>
                <Container>
                    <Menu.Item href='/'>
                        <Image src={ametekLogo} size='small' className='headerImage'/>
                        {textResources.Strings.stcManagement}
                    </Menu.Item>

                    <Menu.Menu position='right'>

                        <Dropdown item text={name as string}>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => {
                                    authenticationStore.signOut()
                                    navigate("/")
                                }}>
                                    <Icon name='sign-out' color='red'/>
                                    {textResources.Strings.signOut}
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        <LanguageSelection />
                        <ConnectionInfo />
                    </Menu.Menu>
                    
                </Container>
            </Menu>
        )
}
