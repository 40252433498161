import './index.css';
import 'semantic-ui-css/semantic.min.css';
import AppWithAuth from "./AppWithAuth";
import {textResources} from "./language/TextResources";
import {ExportEnvironmentSettings} from "./utils/EnvironmentSettings";
import {SetLanguage, SetEnvironment} from "@ametektci/ametek.stcappscommon"
import {createRoot} from "react-dom/client";

SetLanguage(textResources.getCurrentLanguage())
SetEnvironment(ExportEnvironmentSettings())

const root = createRoot(document.getElementById('root')!)
root.render(<AppWithAuth />);
