import {useContext, useEffect, useState} from 'react'
import {Divider, Grid, Header, Input, Button, Segment, Image} from 'semantic-ui-react'
import {textResources} from "../language/TextResources";
import {GetEnvironmentSetting} from "../utils/EnvironmentSettings";
//@ts-ignore
import ccwLogo from "../images/CCWeb_Logo_Black.svg";
import ResetPassword from "../users/ResetPassword";
import {DeleteAccount} from "../users/DeleteAccount";
import {LeaveOrg} from "../users/LeaveOrg";
import {OrganizationContext} from "../contexts/OrganizationContext";
export default function ProfilePanel() {
    const orgContext = useContext(OrganizationContext)
    const [editing, setEditing] = useState(false)
    const [name, setName] = useState(orgContext.user!.name)
    const [email, setEmail] = useState(orgContext.user!.email)
    const [loading, setLoading] = useState<string | null>(null)
    const [organizationName] = useState(orgContext.organization!.name)
    const users = orgContext.getOrganizationUsers()
    const otherAdmins = users.filter(u => u.isAdmin && u.username != orgContext.user!.username)
    
    useEffect(() => {
        setName(orgContext.user!.name)
        setEmail(orgContext.user!.email)
    }, [orgContext.user])
    const toggleEdit = () => setEditing(!editing);

    const clearChanges = () => {
        setEditing(false)
        setName(orgContext.user!.name)
        setEmail(orgContext.user!.email)
    };

    const saveChanges = () => {
        setLoading('nameAndEmail')

        orgContext.updateUserProfile(name, email).then(() => {
            setEditing(false)
            setLoading(null)
            setName( orgContext.user!.name)
            setEmail(orgContext.user!.email)
        });
    };

        return (
            <Segment>
                <Header content={textResources.Strings.userProfile}  size='huge'/>
                <Divider/>

                <Grid stackable>
                    <Grid.Column width={8}>

                        <Segment>
                            <Header content={textResources.Strings.profile} size='large' />

                            <Header dividing content={textResources.Strings.name} color='blue' size='small'/>
                            { editing ?
                                <Input value={name} name='name' onChange={(e, {value}) => setName(value) }
                                       fluid disabled={!!loading}/>
                                :
                                <Header content={name} size='small'/>
                            }

                            <Header dividing content={textResources.Strings.emailAddress} color='blue' size='small'/>
                            { editing ?
                                <Input value={email} name='email' onChange={(e, {value}) => setEmail(value)}
                                       fluid disabled={!!loading}/>
                                :
                                <Header content={email}  size='small'/>
                            }
                            <Header dividing content={textResources.Strings.manageAccount} color='blue' size='small'/>
                            { editing ?
                                <span>
                                    <Button content={textResources.Strings.cancel} onClick={clearChanges} disabled={!!loading}/>
                                    <Button content={textResources.Strings.save} color='blue' onClick={saveChanges} disabled={!!loading}
                                            loading={loading === 'nameAndEmail'}/>
                                </span>
                                :
                                <span>
                                    <Button content={textResources.Strings.editProfile} icon='edit' onClick={toggleEdit} />
                                    <ResetPassword userEmail = {email} />
                                </span>
                            }                            
                            <Header dividing content={textResources.Strings.deleteAccount} color={"red"} size={"small"}/>
                            { /* Certain functionality requires an admin be in the organization. */ }
                            { users.length > 1 && otherAdmins.length == 0? textResources.Strings.assignAnotherAdminFirst : <DeleteAccount /> }

                        </Segment>

                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Segment>
                            <Header content={textResources.Strings.organization} size='large' />

                            <Header dividing content={textResources.Strings.organizationName} color='blue' size='small'/>
                            <Header content={organizationName}  size='small'/>
                            <Header dividing content={textResources.Strings.leaveOrg} color={"red"} size={"small"} />
                            { users.length > 1 && otherAdmins.length == 0? textResources.Strings.assignAnotherAdminFirst : <LeaveOrg /> }
                        </Segment>


                        <Segment>
                            <Header content={textResources.Strings.applications} size='large' />
                            
                            <Segment >
                                <Grid verticalAlign='middle'>
                                    <Grid.Column width={8} >
                                        <Image src={ccwLogo} size='medium' />
                                    </Grid.Column>
                                    <Grid.Column width={8} textAlign='right'>
                                        <Button content={textResources.Strings.launch} icon='external' as='a' href={GetEnvironmentSetting('ccwUrl')}/>
                                    </Grid.Column>
                                </Grid>
                            </Segment>

                        </Segment>

                    </Grid.Column>
                </Grid>
            </Segment>
        )
}
