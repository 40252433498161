import {Button, Form, Header, Message, Segment} from "semantic-ui-react";
import {useContext, useState} from "react";
import {LanguageContext} from "../contexts/LanguageContext";
import {APIContext} from "../contexts/APIContext";
import {OrganizationContext} from "../contexts/OrganizationContext";
import {useNavigate} from "react-router-dom";

export function CreateOrganization() {
    const translate = useContext(LanguageContext)
    const api = useContext(APIContext)
    const orgContext = useContext(OrganizationContext)
    const [orgName, setOrgName] = useState("")
    const navigate = useNavigate()
    const [inProgress, setInProgress] = useState(false)
    const [error, setError] = useState('')
    const createOrg = () => {
        if (!orgName)
            return
        setInProgress(true)
        api.CreateOrg(orgName).then(([success, message]) => {
                if (success) {
                    orgContext.loadOrganization().then(() => navigate("/"))
                } else {

                    setError(message)
                }
            }
        )
            .finally(() => {
                setInProgress(false)
            })
    }
    let errorMessage = ""
    switch (error) {
        case "":
            errorMessage = ""
            break
        case "BAD_CODE":
            errorMessage = translate.getString("requestANewLink")
            break
        case "NEEDS_ADMIN":
        case "REQUIRES_CLEANUP":
            errorMessage = translate.getString("assignAnotherAdminFirst")
            break
        default:
            errorMessage = translate.getString("unknownProblem")
            break
    }
    return (
        <Segment.Group>
            <Segment basic fluid>
                <Header dividing>
                    {translate.getString("createNewOrganization")}
                </Header>
            </Segment>
            <Segment basic fluid>
                <div>
                    {translate.getString("createOrganizationInstructions")}
                </div>
                <br/>
                <Form>
                    <Form.Group widths={"equal"}>
                        <Form.Input
                            value={orgName}
                            onChange={(e, {value}) => setOrgName(value)}
                            label={translate.getString('organizationName')}
                            labelPosition={"left"}
                            fluid
                            autoFocus
                        />
                        <Form.Button color={"blue"} label={"\u00A0"} onClick={() => createOrg()}
                                     disabled={orgName.length < 1 || inProgress}
                                     loading={inProgress}>{translate.getString("createNewOrganization")}</Form.Button>
                    </Form.Group>
                </Form>
                <Message error content={errorMessage} hidden={errorMessage == ""}/>
            </Segment>
            <Segment fluid basic textAlign={"right"} padded={false}>
                {orgContext.organization ?
                    <Button onClick={() => navigate("/")}>{translate.getString("cancel")}</Button> :
                    null
                }
            </Segment>
        </Segment.Group>
    )
}