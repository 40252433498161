import {User} from "../Models/Types/User";
import {Button, Icon} from "semantic-ui-react";
import {useContext, useState} from "react";
import {LanguageContext} from "../contexts/LanguageContext";
import {OrganizationContext} from "../contexts/OrganizationContext";

export function ReSendInvite({user}:{user: User})
{
    enum sentStatus {
        notSent,
        sending,
        sent,
        failed,
    }
    const translate = useContext(LanguageContext)
    const orgContext = useContext(OrganizationContext)
    const [sent, setSent] = useState(sentStatus.notSent)
    const send = () => {
        setSent(sentStatus.sending)
        orgContext.reSendInvite(user.userId).catch(() => {
            setSent(sentStatus.failed)
        }).then(() => {
            setSent(sentStatus.sent)
        })
        
    }
    
    if (user.username)
        return null //this user has already accepted the invite.
    switch (sent)
    {
        case sentStatus.notSent:
            return (
                <Button size={"tiny"} color={"orange"} onClick={send} >
                    {translate.getString("sendInviteAgain")}
                </Button>
            )
        case sentStatus.sending:
            return (
                <Button size={"tiny"} color={"orange"} disabled={true} loading={true} >
                    {translate.getString("sendInviteAgain")}
                </Button>
            )
        case sentStatus.sent:
            return (
                <Button size={"tiny"} color={"orange"} disabled={true} >
                    <Icon name={"check"} />
                </Button>
            )
        case sentStatus.failed:
            return (
                <Button size={"tiny"} color={"orange"} disabled={true} >
                    <Icon name={"x"} />
                </Button>
            )
    }
    
}