import {useContext, useState} from 'react';
import {Button, Modal} from 'semantic-ui-react';
import {textResources} from "../language/TextResources";
import {DateUtils, SubscriptionBill} from '@ametektci/ametek.stcappscommon';
import {SubscriptionsContext} from "../contexts/SubscriptionsContext";
export interface CancelSubscriptionModalProps {
    subscription: SubscriptionBill
}
export default function CancelSubscriptionModal (props: CancelSubscriptionModalProps) {
    const subContext = useContext(SubscriptionsContext)
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)

    const open = () => setVisible(true);

    const close = () => {
        if ( ! loading) {
            setVisible(false)
        }
    };

    const cancelSubscription = () => {
        setLoading(true)
            subContext.cancelSubscription(props.subscription.subscriptionId).then(() => {
            setLoading(false)
            setVisible(false)
        })
    }

        return (
            <Modal open={visible} size='tiny'
                   trigger={
                       <Button content={textResources.Strings.cancel} color='red' size='tiny' icon='delete'
                               onClick={open} /> } >
                <Modal.Header>{textResources.Strings.cancelSubscription}</Modal.Header>
                <Modal.Content >
                    {textResources.Strings.seatHasAccessUntil} <b>{DateUtils.FormatDate(props.subscription.nextBillingDate)}</b>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={close} disabled={loading} content={textResources.Strings.close}/>
                    <Button color='red' content={textResources.Strings.confirmCancellation} onClick={cancelSubscription} floated='right'
                            disabled={loading} loading={loading}/>
                </Modal.Actions>
            </Modal>
        )
}