import {Button, Card, Grid, GridColumn, GridRow, Header, Icon, Label, Segment, Table} from "semantic-ui-react";
import {useContext, useEffect, useState} from "react";
import {SubscriptionsContext} from "../contexts/SubscriptionsContext";
import {LanguageContext} from "../contexts/LanguageContext";

export function PaymentHistory() {
    const translate = useContext(LanguageContext)
    const subsContext = useContext(SubscriptionsContext)
    const orders = subsContext.orderHistory
    const [page, setPage] = useState(0)
    const perPage = 10
    const lastPage = Math.floor(orders.length / perPage)
    const start = page * perPage
    useEffect(() => {
        if (page == lastPage) {
            subsContext.loadMoreHistory()
        }
    }, [page, lastPage])
    return (
        <Segment>
            {
                orders.slice(start, start + perPage).map((order, idx) =>
                    <Card key={idx} fluid={true}>
                        <Card.Header textAlign={"center"}>
                            <Header
                                block={true}>{translate.getString("orderNumberHeader").replace("###", order.id.toString())} </Header>
                        </Card.Header>
                        <Card.Content>

                            {order.postSaleInfo.invoices.map((inv, idx) => <div key={idx}>
                                <Segment compact={true} basic={true}>
                                    <Grid columns={"equal"}>
                                        <GridRow>
                                            <GridColumn><Header
                                                size={"small"}> {translate.getString("invoiceIdHeader").replace("###", inv.id.toString())} </Header></GridColumn>
                                            <GridColumn><a
                                                href={inv.url} target={"_blank"}><Button size={"mini"} compact
                                                                                         color={"blue"}>{translate.getString("invoiceViewLinkText")}</Button></a></GridColumn>
                                        </GridRow>
                                        <GridRow>
                                            <GridColumn>
                                                <Label>{translate.getString("totalCost")}</Label>
                                            </GridColumn>
                                            <GridColumn>
                                                {order.cart.totalCost} {order.cart.chargedCurrency}
                                            </GridColumn>
                                        </GridRow>
                                    </Grid>
                                    <Table
                                        size={"small"}
                                        striped={true}
                                        compact={true}
                                        textAlign={"center"}
                                    >
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>
                                                    {translate.getString("dateTableHeader")}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {translate.getString("softDescriptorTableHeader")}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {translate.getString("acceptedStatusTableHeader")}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {translate.getString("amountTableHeader")}
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {inv.transaction.map((trans, transIdx) => <Table.Row key={transIdx}>
                                                <Table.Cell>{trans.created}</Table.Cell>
                                                <Table.Cell>{trans.softDescriptor}</Table.Cell>
                                                <Table.Cell>{trans.status}</Table.Cell>
                                                <Table.Cell>{trans.amount} {trans.currency}</Table.Cell>
                                            </Table.Row>)}
                                        </Table.Body>
                                    </Table>
                                </Segment>
                            </div>)}
                        </Card.Content>
                    </Card>)
            }
            <Grid padded={false}>
                <Grid.Row>
                    <Grid.Column width={1}>
                        <Button
                            disabled={page == 0}
                            onClick={() => setPage(page - 1)}
                            icon={<Icon name={"arrow left"}/>}
                            fluid
                        />
                    </Grid.Column>
                    <Grid.Column width={14} textAlign={"center"}>
                        {translate.getString(subsContext.allHistoryLoaded ? "page1of2" : "page1of2+").replace("##A", (page + 1).toString()).replace("##B", (lastPage + 1).toString())}
                    </Grid.Column>
                    <Grid.Column width={1}>
                        <Button
                            disabled={page >= lastPage}
                            onClick={() => setPage(page + 1)}
                            icon={<Icon name={"arrow right"}/>}
                            fluid
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>


        </Segment>
    )
}