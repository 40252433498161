import {AddressInfo} from "@ametektci/ametek.stcappscommon/src/DataStructures/AddressInfo";
import {useContext} from "react";
import {SubscriptionsContext} from "../contexts/SubscriptionsContext";

interface AddressDisplayProps {
    addr: AddressInfo
}
export function AddressDisplay(props: AddressDisplayProps)
{
    const subscriptionContext = useContext(SubscriptionsContext)
    if (props.addr.address == undefined || props.addr.address == "")
        return <div>----</div>
    return (
        <div>
            {props.addr.address}<br/>
            {props.addr.address2}<br/>
            {props.addr.city}, {props.addr.state} {props.addr.zip}<br/>
             {subscriptionContext.countries.find(c => c.code == props.addr.country)?.country }
        </div>
    )
}