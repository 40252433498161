import {JSX, PropsWithChildren, useContext, useEffect, useState} from "react";
import {Button, Header, Loader, Message, Modal} from "semantic-ui-react";
import {OrganizationContext} from "../contexts/OrganizationContext";
import { useNavigate } from 'react-router-dom';
import {LanguageContext} from "../contexts/LanguageContext";

export function VerifySwitch(props: PropsWithChildren) {
    const translate = useContext(LanguageContext)
    const orgContext = useContext(OrganizationContext)
    const navigate = useNavigate()
    const [ready, setReady] = useState(orgContext.loading)
    useEffect(() => {
        if (orgContext.loading)
            return;
        if (orgContext.organization != null)
            return;
        setReady(true) //default to allowing switching organizations if the user is not in an organization.
    },[orgContext, orgContext.organization, orgContext.loading])
    
    if (!ready)
        return (<>
            <Modal open={true}>
            <Modal.Header>
                <Header>{translate.getString("switchOrganizationsHeader")}</Header>
            </Modal.Header>
            <Modal.Content>
                <Message warning={true} style={{textAlign: "center"}}>{translate.getString("rejoinWarning")
                    .replace("**USER**", orgContext.user?.name?.replaceAll(" ", " ") ?? translate.getString("loading"))
                    .replace("**EMAIL**", orgContext.user?.email ?? translate.getString("loading"))
                    .replace("**ORG**", orgContext.organization?.name?.replaceAll(" ", " ") ?? translate.getString("loading"))
                }</Message>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => navigate("/")}>
                    {translate.getString("cancel")}
                </Button>
                <Button color={"red"} onClick={() => {
                    setReady(true)
                }}>
                    {translate.getString("Okay")}
                </Button>
            </Modal.Actions>
        </Modal>
            <Loader />
            </>)
    return props.children as JSX.Element
}