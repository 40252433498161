import {useContext, useState} from 'react';
import {Button, Form, Header, Icon, Label, Modal} from 'semantic-ui-react';
import { validate}  from "email-validator";
import {textResources} from "../language/TextResources";
import {OrganizationContext} from "../contexts/OrganizationContext";

export default function InviteUser (){
    const orgContext = useContext(OrganizationContext)
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState<string>("")
    const [emailValidationError, setEmailValidationError] = useState(false)
    const onInviteFinished = () => {
        setLoading(false)
        setVisible(false)
    }

    const open = () => {
        setVisible(true)
        setEmail("")
        setEmailValidationError(false)
    };

    const close = () => {
        if ( ! loading) {
            setVisible(false)
        }
    };

    const inviteUser = () => {
        let validEmail = validate(email);

        if (validEmail) {
            setLoading(true)
            orgContext.inviteUserToOrganization(email).then(onInviteFinished)
        }
        else {
            setEmailValidationError(true)
        }
    }

        return (
            <Modal size='tiny' open={visible}
                   trigger={
                       <Button animated color='green' size='small'
                               onClick={open}>
                           <Button.Content visible content={textResources.Strings.emailInviteLink}/>
                           <Button.Content hidden>
                               <Icon name='plus' />
                           </Button.Content>
                       </Button>
                   } >
                <Modal.Header>{textResources.Strings.inviteUserViaEmail}</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Header size='small' dividing content={textResources.Strings.emailAddress}/>
                        <Form onSubmit={inviteUser}>
                            <Form.Input onChange={(e, {value}) => setEmail(value)} error={emailValidationError}
                                        placeholder={textResources.Strings.emailAddress} fluid/>
                            { emailValidationError ?
                                <Label pointing basic color='red' content={textResources.Strings.enterValidEmail} />
                                : null }
                        </Form>

                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={close} disabled={loading} content={textResources.Strings.cancel} />
                    <Button positive disabled={loading}
                            type={"submit"}
                            loading={loading} onClick={inviteUser} content={textResources.Strings.sendInvite} />
                </Modal.Actions>
            </Modal>
        )
}