import {AddressInfo} from "@ametektci/ametek.stcappscommon/src/DataStructures/AddressInfo";
import {Button, Form, Grid, Header, Modal} from "semantic-ui-react";
import {AddressDisplay} from "./AddressDisplay";
import {useContext, useState} from "react";
import {LanguageContext} from "../contexts/LanguageContext";

interface AddressSelectionProps {
    addresses: Array<AddressInfo>
    asEntered: AddressInfo
    onSelected: (addr: AddressInfo) => void
    onCancel: () => void
    show: boolean
}
export function AddressSelection (props: AddressSelectionProps) {
    const translate = useContext(LanguageContext)
    const [selected, setSelected] = useState<number>()
    const onAccept = () =>
    {
        if (selected == undefined)
            return
        if (selected == -1)
            props.onSelected(props.asEntered)
        props.onSelected(props.addresses[selected])
    }
    return (
        <Modal open={props.show} onClose={() => props.onCancel}>
            <Modal.Header>{translate.getString("selectAddress")}</Modal.Header>
            <Modal.Content>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Header size={"small"}>{translate.getString("asFound")}</Header>
                        </Grid.Column>
                    </Grid.Row>
                    {props.addresses.map((addr,idx) =>
                        (
                            <Grid.Row key={idx}>
                                <Grid.Column width={1}>
                                    <Form.Radio
                                        checked={selected == idx}
                                        onChange={() => setSelected(idx)}
                                    />
                                </Grid.Column>
                                <Grid.Column width={15}>
                                    <AddressDisplay addr={addr}/>
                                </Grid.Column>
                            </Grid.Row>
                        )
                    )}
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Header size={"small"}>{translate.getString("asEntered")}</Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={1}>
                        <Form.Radio
                            checked={selected == -1}
                            onChange={() => setSelected(-1)}
                        />
                    </Grid.Column>
                    <Grid.Column width={15}>
                        <AddressDisplay addr={props.asEntered}/>
                    </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button 
                    onClick={() => props.onCancel()}
                    color={"red"}
                >{translate.getString("cancel")}</Button>
                <Button 
                    onClick={onAccept}
                    color={"blue"}
                    disabled={selected == undefined}
                >
                    {translate.getString("select")}
                </Button>
            </Modal.Actions>
        </Modal>
    )
}