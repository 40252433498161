import {useContext, useState} from 'react';
import {Button,  Grid, Header, Modal} from 'semantic-ui-react';
import {GetCreditCardIcon} from "../utils/CurrencyUtils";
import {CapitalizeFirstLetter} from "../utils/WindowUtils";
import {textResources} from "../language/TextResources";
import {CreditCardInfo} from "@ametektci/ametek.stcappscommon";
import {SubscriptionsContext} from "../contexts/SubscriptionsContext";
export interface UpdateCardModalProps {
    card: CreditCardInfo
}
export default function UpdateCardModal(props: UpdateCardModalProps) {
    const subsContext = useContext(SubscriptionsContext)
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState<string | null>(null)

    const open = () => {
        setVisible(true)
    };

    const close = () => {
        if ( !loading) {
            setVisible(false)
        }
    };


    const removeCard = () => {
        setLoading('removeCard')

        subsContext.removePaymentCard(props.card.creditCard.cardLastFourDigits, props.card.creditCard.cardType).then((s) => {
            setLoading(null)
            subsContext.refresh()
        });
    };

        return (
            <Modal open={visible} size='tiny'
                   trigger={
                       <Button content={textResources.Strings.update} color='blue' size='tiny'
                               onClick={open} /> } >
                <Modal.Header>{textResources.Strings.update} {CapitalizeFirstLetter(props.card.creditCard.cardType)}</Modal.Header>
                <Modal.Content>

                    <Grid columns={2} verticalAlign='middle'>
                        <Grid.Column>
                            <Header icon={GetCreditCardIcon(props.card.creditCard.cardType)} content={CapitalizeFirstLetter(props.card.creditCard.cardType)}
                                    color='blue' size='large'/>
                        </Grid.Column>
                        <Grid.Column>
                            <p>**** **** **** {props.card.creditCard.cardLastFourDigits}</p>
                        </Grid.Column>
                    </Grid>

                    <Header content={props.card.billingContactInfo.firstName + ' ' + props.card.billingContactInfo.lastName}
                    />

                    <Header size='small' color='red' content={textResources.Strings.removeCard} dividing/>

                    <Button color='red' content={textResources.Strings.removeCard} onClick={removeCard}
                            disabled={!!loading} loading={loading === 'removeCard'}/>

                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={close} disabled={!!loading} content={textResources.Strings.close}/>
                </Modal.Actions>
            </Modal>
        )
}