import {useContext} from 'react'
import {Grid, Header, List, Segment} from 'semantic-ui-react'
import InviteUser from "./InviteUser";
import RemoveUser from "./RemoveUser";
import SetUserAdmin from "./SetUserAdmin";
import {textResources} from "../language/TextResources";
import {OrganizationContext} from "../contexts/OrganizationContext";
import {ReSendInvite} from "./ReSendInvite";

export default function UsersPanel() {
    const orgContext = useContext(OrganizationContext)
    const currentUser = orgContext.user!
    const users = orgContext.getOrganizationUsers()

        return (
            <Segment>
                <Header content={textResources.Strings.users} size='huge'/>

                <Header size='small' content={textResources.Strings.emailInviteLink} dividing/>
                <InviteUser/>

                <Header size='small' content={textResources.Strings.currentUsers} dividing/>
                <List>
                    {users.map(user => (
                        <List.Item key={user.email}>
                            <Segment clearing>
                                <Grid verticalAlign='middle'>
                                    <Grid.Column width={4}>
                                        <Header content={user.name} subheader={user.isAdmin ? textResources.Strings.admin : textResources.Strings.standardUser}/>
                                    </Grid.Column>
                                    <Grid.Column width={7}>
                                        {user.email}
                                    </Grid.Column>
                                    <Grid.Column width={5}>
                                        { currentUser.userId === user.userId ? null :
                                            <span>
                                                <RemoveUser user={user}/>
                                                <SetUserAdmin user={user} />
                                                <ReSendInvite user={user} />
                                            </span>
                                        }
                                    </Grid.Column>
                                </Grid>
                            </Segment>
                        </List.Item>
                    ))}
                </List>
            </Segment>
        )

}