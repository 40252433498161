import {Button, Icon, Modal} from "semantic-ui-react";
import {textResources} from "../language/TextResources";
import {useContext, useState} from "react";
import {OrganizationContext} from "../contexts/OrganizationContext";

export function LeaveOrg() {
    const orgContext = useContext(OrganizationContext)
    const [open, setOpen] = useState(false)
    return (
        <Modal
            size={"small"}
            open={open}
            trigger={
                <Button 
                    content={textResources.Strings.leaveOrg}
                    onClick={() => setOpen(true)}
                    icon={<Icon name={"close"}/>}
                />}
        >
            <Modal.Header>
                {textResources.Strings.leaveOrg}
            </Modal.Header>
            <Modal.Content>
                {textResources.Strings.leaveOrgWarning}
            </Modal.Content>
            <Modal.Actions>
                <Button content={textResources.Strings.leaveOrg} color={"red"} onClick={() => {orgContext.removeUser(orgContext.user?.email! ).then(() => orgContext.loadOrganization())}}/>
                <Button content={textResources.Strings.close} onClick={() => setOpen(false)} />
            </Modal.Actions>
        </Modal>
    )
}