import {createContext, PropsWithChildren, useEffect, useState} from "react";
import {authenticationStore, Product} from "@ametektci/ametek.stcappscommon";
import axios, {AxiosResponse, HttpStatusCode} from "axios";
import {GetEnvironmentSetting} from "../utils/EnvironmentSettings";
import {textResources} from "../language/TextResources";
import {GetEnvironmentRequest, GetEnvironmentResponse} from "../Models/API/GetEnvironmentRequest";
import {AddressInfo} from "@ametektci/ametek.stcappscommon/src/DataStructures/AddressInfo";
export const defaultProduct = {name: "Loading", tiers: [{billingPlans: [], name:"Loading", features: [], nameString: textResources.Strings.loading}],}
export const APIContext = createContext({
    FetchPlan: (product: string) => defaultProduct as Product,
    JoinOrg: (code: string) => Promise.resolve([false,""] as [boolean, string]),
    CreateOrg: (orgName: string) => Promise.resolve([false,""] as [boolean, string]),
    VerifyAddress: (addr: AddressInfo) => Promise.resolve([] as Array<AddressInfo>),
    blueSnapLink: "",
    appVersion: "",
})
export function ApiContextWrapper(props: PropsWithChildren) {
    const [loadedProducts, setLoadedProducts] = useState<{ [key: string]: Product }>({})
    const [blueSnapLink, setBlueSnapLink] = useState("")
    const [appVersion, setAppVersion] = useState(textResources.Strings.unknown)
    const managementAxios = axios.create({
        baseURL: GetEnvironmentSetting('managementConsoleApi') as string,
        validateStatus: () => true
    })
    managementAxios.interceptors.request.use( async (req) => {
        req.headers.Authorization = "bearer " + await authenticationStore.getAccessToken()
        return req
    })
    useEffect(() => {
        getEnvironment()
    }, [])
    const getPlansFromDatabase = async (product: string) => {
        try {
            var response = await managementAxios.get<{subscribable: Product}>(`/Plans/${product}`)
            if (response.status == 200)
            {
                response.data.subscribable.tiers.forEach((t) => {
                    t.nameString = textResources.getTextDynamic(t.name)
                    t.features.forEach((f) => {
                        f.name = textResources.getTextDynamic(f.name)
                    })
                    t.billingPlans.forEach((bp) => {
                        bp.description = textResources.getTextDynamic(bp.description)
                        bp.priceString = textResources.Strings.priceString.replace("###", bp.price.toString())
                        bp.intervalString = textResources.getTextDynamic("PER_"+bp.length)
                    })
                })
                setLoadedProducts({...loadedProducts, [product]: response.data.subscribable})
            }
        }
        catch (e) {
            console.log(e)
        }
    }
    const FetchPlan = (product: string): Product => {
        if (!loadedProducts[product]) 
            getPlansFromDatabase(product)
    return loadedProducts[product] ?? defaultProduct
    }
    const getEnvironment = async () => {
        let response = await managementAxios.get<GetEnvironmentResponse, AxiosResponse<GetEnvironmentResponse>, GetEnvironmentRequest>(
            `/environment`)
        setBlueSnapLink(response.data.blueSnap)
        setAppVersion(response.data.version)
    }
    const JoinOrg = async (code: string) => {
        let joined = await managementAxios.post("/JoinOrganization", {
            JoinOrganizationCode : code
        })
        console.log(joined)
        return [(joined.status == HttpStatusCode.Ok || joined.status == HttpStatusCode.NoContent), joined.data.responseStatus?.message ?? ""] as [boolean, string]
    }
    const CreateOrg = async (name: string) => {
        let joined = await managementAxios.post("/CreateOrganization", {
            OrganizationName : name
        })
        console.log(joined)
        return [(joined.status == HttpStatusCode.Ok || joined.status == HttpStatusCode.NoContent), joined.data.responseStatus?.message ?? ""] as [boolean, string]
    }
    const VerifyAddress = async (addr: AddressInfo) => {
        let response = await managementAxios.post<{ reccommendedAddreses:AddressInfo[] }>("/Billing/ValidateAddress", {address: addr})
        if (response.status == HttpStatusCode.Ok)
            return response.data.reccommendedAddreses ?? []
        else throw response
    }
    return (<APIContext.Provider value={{
        FetchPlan,
        JoinOrg,
        CreateOrg,
        VerifyAddress,
        blueSnapLink,
        appVersion,
    }
    }>
        {props.children}
    </APIContext.Provider> )
}