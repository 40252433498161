import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as _CookieConsent from "vanilla-cookieconsent";

export default function CookieConsent(){
        _CookieConsent.run({

            categories: {
                Functional: {
                    enabled: true,
                    readOnly: true
                },
            },
            language: {
                default: 'en',
                translations: {
                    en: {
                        consentModal: {
                            title: 'We use cookies!',
                            description: 'Our website ONLY uses essential cookies. These are used to make sure the website works as expected.',
                            acceptAllBtn: 'Okay',
                        },
                        preferencesModal: {
                            title: 'Cookie preferences',
                            acceptNecessaryBtn: 'Accept Necessary',
                            closeIconLabel: 'Close modal',
                            sections: [
                                {
                                    title: 'We only use cookies that are required to make the site function. This includes cookies from our payment processor, which may collect information for the purpose of fraud prevention',
                                },
                                {
                                    title: 'Strictly Necessary cookies',

                                    //this field will generate a toggle linked to the 'necessary' category
                                    linkedCategory: 'Functional',
                                    cookieTable: {
                                        caption: 'List of cookies',
                                        headers: {
                                            name: 'Name',
                                            description: 'Description',
                                            duration: 'Duration'
                                        },
                                        body: [
                                            {
                                                name: 'betteruptime_announcement_bar_dismissed',
                                                description: 'Keeps the maintenance announcement banner hidden if the user has already dismissed it.',
                                                duration: 'Session'
                                            },
                                            {
                                                name: 'cc_cookie',
                                                description: 'Used to manage cookie consent.',
                                                duration: '5 months'
                                            },
                                            {
                                                name: '*.bluesnap.com',
                                                description: 'Used by our payment processor.'
                                            }
                                        ]
                                    }
                                },
                            ],
                        }
                    }
                }
            }
        });
}