import {useEffect, useState} from 'react';
import {Dropdown, Icon} from 'semantic-ui-react';
import {textResources, Language} from "./TextResources";

const LanguageOptions = [
    {flag : 'us', key : 'english', value : 'english', text : "English"},
    //{flag : 'fr', key : 'french', value : 'french', text : "Français"},
    //{flag : 'de', key : 'german', value : 'german', text : "Deutsche"},
    //{flag : 'es', key : 'spanish', value : 'spanish', text : "Español"},
    //{flag : 'cn', key : 'chinese', value : 'chinese', text : "中文"}
] as const;

export default function LanguageSelection() {
    const [currentLanugage, setCurrentLanguage] = useState(textResources.getCurrentLanguage())
    //We can't currently provide a language other than english. if somehow we get a language other than english, reset it.
    useEffect(() => {
        if (currentLanugage != "english")
        {
            textResources.setCurrentLanguage("english")
            setCurrentLanguage("english")
        }
    })
    const onLanguageChange = (language: Language) => {
        if (language != currentLanugage)
        {
            textResources.setCurrentLanguage(language)
            setCurrentLanguage(language)
        }
    }
    
        return (
            <Dropdown 
                item 
                trigger={<Icon name='language' size='large' />} 
                onChange={(e, data) => onLanguageChange(data.value as Language)} 
                options={LanguageOptions.concat()} 
            />
        )
}
