const _envSettings = {
    'local' : {
        'amplifyConfig' : {
            region: 'US_EAST_1',
            userPoolId: 'us-east-1_bHCVEyuDl',
            userPoolWebClientId: '1hracheeddij42216kn4de5jb8',
        },
        'documentationUrl' : 'https://help.crystalcontrolweb.com',
        'managementConsoleApi' : 'https://localhost:5001',
        'CcwApiUrl' : 'https://dev-api.crystalcontrolweb.com',
        'ccwUrl' : 'https://dev.crystalcontrolweb.com',
    },
    'dev' : {
        'amplifyConfig' : {
            region: 'US_EAST_1',
            userPoolId: 'us-east-1_bHCVEyuDl',
            userPoolWebClientId: '1hracheeddij42216kn4de5jb8'
        },
        'documentationUrl' : 'https://help.crystalcontrolweb.com',
        'managementConsoleApi' : 'https://dev-api.stcapplications.com',
        'CcwApiUrl' : 'https://dev-api.crystalcontrolweb.com',
        'ccwUrl' : 'https://dev.crystalcontrolweb.com',
    },
    'fargate' : {
        'amplifyConfig' : {
            region: 'US_EAST_1',
            userPoolId: 'us-east-1_bHCVEyuDl',
            userPoolWebClientId: '2qreq103nvpb5o5pba5pc09ipc'
        },
        'documentationUrl' : 'https://help.crystalcontrolweb.com',
        'managementConsoleApi' : 'https://dev-api-fargate.stcapplications.com/',
        'CcwApiUrl' : 'https://dev-api-fargate.crystalcontrolweb.com',
        'ccwUrl' : 'https://dev-fargate.crystalcontrolweb.com',
    },
    'staging' : {
        'amplifyConfig' : {
            region: 'US_EAST_1',
            userPoolId: 'us-east-1_Sof0NHnPc',
            userPoolWebClientId: '322ajr4o7534qdqc091slvfbcq',
        },
        'documentationUrl' : 'https://help.crystalcontrolweb.com',
        'managementConsoleApi' : 'https://staging-api.stcapplications.com',
        'CcwApiUrl' : 'https://staging-api.crystalcontrolweb.com',
        'ccwUrl' : 'https://staging.crystalcontrolweb.com',
    },
    'production' : {
        'amplifyConfig' : {
            region: 'US_EAST_1',
            userPoolId: 'us-east-1_Sof0NHnPc',
            userPoolWebClientId: '322ajr4o7534qdqc091slvfbcq',
        },
        'documentationUrl' : 'https://help.crystalcontrolweb.com',
        'managementConsoleApi' : 'https://api.stcapplications.com',
        'CcwApiUrl' : 'https://api.crystalcontrolweb.com',
        'ccwUrl' : 'https://crystalcontrolweb.com',
    }
} as const;

type env = keyof typeof _envSettings
type envVar = keyof typeof _envSettings[env]
export const GetEnvironmentSetting = function(key : envVar) {
    // determine the env
    let env = GetEnvironment();
    // get the appropriate value for the env
    let value = _envSettings[env][key];
    // if we couldn't find it, alert ray gun, something is missing
    if ( value == null ) {
        console.error('unknown environment variable', key);
    }

    return value;
};

export const GetEnvironment = function() : env {
    // return 'dev'
    if (window.location.hostname.includes('localhost'))
        return 'local';
    else if (window.location.hostname.includes('dev'))
        return 'dev';
    else if (window.location.hostname.includes('staging'))
        return 'staging';
    else if (window.location.hostname.includes('fargate'))
        return 'fargate';

    return 'production';
};

export const ExportEnvironmentSettings = function() {
    let env = GetEnvironment();
    return _envSettings[env];
};
