import {AddressInfo} from "@ametektci/ametek.stcappscommon/src/DataStructures/AddressInfo";
import {useContext, useEffect, useState} from "react";
import {Accordion, AccordionContent, Button, Form} from "semantic-ui-react";
import {textResources} from "../language/TextResources";
import {SubscriptionsContext} from "../contexts/SubscriptionsContext";
import {LanguageContext} from "../contexts/LanguageContext";
import {APIContext} from "../contexts/APIContext";
import {AddressDisplay} from "./AddressDisplay";
import {AddressSelection} from "./AddressSelection";
import {messageQueue} from "@ametektci/ametek.stcappscommon";

export interface AddressInfoProps {
    address: AddressInfo
    onSetAddress: (addr: AddressInfo) => void
    visible: boolean
    hideAddr: boolean
    onCancel: () => void
}

export function AddressInfoForm(props: AddressInfoProps)
{
    const api = useContext(APIContext)
    const subscriptionContext = useContext(SubscriptionsContext)
    const translate = useContext(LanguageContext)
    const [addr, setAddr] = useState(props.address)
    const validationAvailable = addr.country == 'us'  || addr.country == 'ca'
    const [foundAddresses, setFoundAddresses] = useState([] as Array<AddressInfo>)
    const [showAddressSelection, setShowAddressSelection] = useState(false)
    useEffect(() => {
        setAddr(props.address)
    }, [props.address.address, props.address.address2, props.address.city, props.address.country, props.address.state, props.address.zip])
    
    const onCheckAddress = () => {
        //Look up address
        api.VerifyAddress(addr).then((foundAddrs) => {
            setFoundAddresses(foundAddrs)
            setShowAddressSelection(true)
        })
            .catch((e) => {
                console.error(e)
                messageQueue.sendError(translate.getString("errorLookingUpAddress"))
            })
    }
    const onAcceptAddress = (newAddr : AddressInfo) => {
        console.log("on set address called")
        setShowAddressSelection(false)
        props.onSetAddress(newAddr)
    }
    const onCancel = () => {
        setAddr(props.address)
        props.onCancel()
    }
    
    return (
        <>
        <Accordion.Content active={props.visible}>
            <AddressSelection addresses={foundAddresses} asEntered={addr} onSelected={onAcceptAddress} onCancel={() => setShowAddressSelection(false)} show={showAddressSelection} />
            <Form.Input
                label={textResources.Strings.addressLine1Placeholder}
                onChange={(e, {value}) => setAddr((a) => ({...a, address: value}))}
                value={addr.address}
                maxLength="100"
            />
            <Form.Input
                label={textResources.Strings.addressLine2Placeholder}
                onChange={(e, {value}) => setAddr((a) => ({...a, address2: value}))}
                value={addr.address2}
                maxLength="100"
            />
            <Form.Input
                label={textResources.Strings.cityPlaceholder}
                onChange={(e, {value}) => setAddr((a) => ({...a, city: value}))}
                value={addr.city}
            />
            <Form.Dropdown
                label={textResources.Strings.countryPlaceholder} search
                searchInput={{type: 'text'}}
                selection
                value={addr.country}
                onChange={(e, {value}) => setAddr((a) => ({...a, country: value as string}))}
                options={subscriptionContext.countries.map(country => {
                    return {key: country.code, flag: country.code.toLowerCase(), text: country.country, value: country.code}
                })}
            />
            <Form.Group>
                {addr.country === 'us' || addr.country == 'ca' ?
                    <Form.Dropdown
                        label={textResources.Strings.stateProvincePlaceholder} search
                        searchInput={{type: 'text'}} selection width={9}
                        name='state'
                        onChange={(e, {value}) => setAddr((a) => ({...a, state: value as string}))}
                        options={subscriptionContext.states.filter(s => s.countryCode == addr.country).map(state => {
                            return {key: state.stateCode, text: state.name, value: state.stateCode}
                        })}
                        value={addr.state}
                    /> : null
                }

                <Form.Input
                    label={translate.getString("postalCode")}
                    placeholder='Postal Code'
                    width={7}
                    value={addr.zip}
                    maxLength="20"
                    onChange={(e, {value}) => setAddr((a) => ({...a, zip: value}))}
                />
            </Form.Group>
            <div style={{textAlign:"right"}}>
                <Button
                    content={translate.getString("cancel")}
                    onClick={onCancel}
                    color={"red"}
                />
            { validationAvailable? 
                <Button 
                    content={translate.getString("checkAddress")} 
                    onClick={() => onCheckAddress()}
                    color={"blue"}
                /> : 
                <Button 
                    content={translate.getString("acceptAddress")} 
                    onClick={() => onAcceptAddress(props.address)} 
                    color={"blue"}
                />}
            </div>
            
        </Accordion.Content>
            <AccordionContent active={!props.visible && !props.hideAddr}>
                <AddressDisplay addr={props.address}/>
            </AccordionContent>
        </>
    )
}