import {Button, Icon, Modal} from "semantic-ui-react";
import {textResources} from "../language/TextResources";
import {useContext, useState} from "react";
import {Auth, authenticationStore} from "@ametektci/ametek.stcappscommon";
import {OrganizationContext} from "../contexts/OrganizationContext";
import {useNavigate} from "react-router-dom";

export function DeleteAccount() {
    const orgContext = useContext(OrganizationContext)
    const [open, setOpen] = useState(false)
    const navigate = useNavigate();
    const close = () => {
        setOpen(false)
    }
    const tryDelete = () => {
        //Asking the user to re-enter their password at this step would be ideal. Sadly as of right now, that is not possible with Cogito.

            orgContext.removeUser(orgContext.user?.email!).then((success) => {
                if (success)
                    Auth.deleteUser().then(() => {
                        authenticationStore.signOut()
                        navigate("/")
                    })
            })
    }
    return (
        <Modal
             trigger={
                <Button content={textResources.Strings.deleteAccount} onClick={() => setOpen(true)} />
            }
               open={open}
               onClose={() => close()}
       >
            <Modal.Header>
                {textResources.Strings.deleteAccount}
            </Modal.Header>
            <Modal.Content>
                    {textResources.Strings.deleteAccountWarning}
            </Modal.Content>
            <Modal.Actions>
                <Button icon={<Icon name={"delete"}/> } content={textResources.Strings.deleteAccount} onClick={() => tryDelete()} color={"red"}/>
                <Button content={textResources.Strings.cancel} onClick={() => close()} />
            </Modal.Actions>
        </Modal>
    )
}