import {useContext, useState} from 'react';
import {Button, Header, Modal} from 'semantic-ui-react';
import {textResources} from "../language/TextResources";
import {User} from "../Models/Types/User";
import {OrganizationContext} from "../contexts/OrganizationContext";
export interface SetUserAdminProps {
    user: User
}
export default function SetUserAdmin(props: SetUserAdminProps) {
    const orgContext = useContext(OrganizationContext)
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)

    const open = () => {
        setVisible(true)
    };

    const close = () => {
        if ( ! loading) {
            setVisible(false)
        }
    };

    const setAdmin = () => {
        setLoading(true)
        
        orgContext.setUserAdmin(props.user.email, !props.user.isAdmin).then(() => {
            setLoading(false)
            setVisible(false)
        })
    };

        return (
            <Modal size='tiny' open={visible}
                   trigger={<Button color='blue' size={'tiny'} content={props.user.isAdmin ? textResources.Strings.setAsStandard : textResources.Strings.setAsAdmin}
                                    onClick={open}/>} >
                <Modal.Header content={props.user.isAdmin ? textResources.Strings.setAsStandard : textResources.Strings.setAsAdmin} />
                <Modal.Content>
                    <Modal.Description>
                        <Header content={props.user.name} />
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={close} disabled={loading} content={textResources.Strings.cancel}/>
                    <Button disabled={loading} loading={loading} color='blue'
                            onClick={setAdmin} content={props.user.isAdmin ? textResources.Strings.setAsStandard : textResources.Strings.setAsAdmin} />
                </Modal.Actions>
            </Modal>
        )
}
