import {useContext} from 'react'
import {Header, Icon, Menu, Segment} from 'semantic-ui-react'
import {textResources} from "../language/TextResources";
import {LanguageContext} from "../contexts/LanguageContext";
import {Outlet, useLocation, useNavigate} from "react-router-dom";

export default function BillingPanel() {
    const translate = useContext(LanguageContext)
    const navigate = useNavigate()
    const url = useLocation()
    const page = url.pathname.split("/").filter(piece => piece != "")[1]
    return (
        <Segment>
            <Header content={textResources.Strings.billing} size='huge'/>

            <Menu tabular size='huge'>
                <Menu.Item
                    active={page === 'PaymentMethod'}
                    onClick={() => navigate('PaymentMethod')}
                    icon={<Icon name='credit card'/>}
                    content={textResources.Strings.paymentMethods}
                />
                <Menu.Item
                    active={page == 'History'}
                    icon={<Icon name={"history"}/>}
                    content={translate.getString("paymentHistory")}
                    onClick={() => navigate('History')}
                />
                <Menu.Item
                    active={page === 'activeSubscriptions'}
                    onClick={() => navigate('activeSubscriptions')}
                    icon={<Icon name='file alternate'/>}
                    content={translate.getString("activeSubscriptions")}
                />
            </Menu>
            <Outlet />
        </Segment>
    )
}