import {PropsWithChildren, useContext} from "react";
import {Container} from "semantic-ui-react";
import {LoadingPage} from "@ametektci/ametek.stcappscommon";
import {OrganizationContext} from "./contexts/OrganizationContext";
import Header from './global/Header';

export function WithLayout(props: PropsWithChildren)
{
    const orgContext = useContext(OrganizationContext)
    return <>
        <Header/>
        <Container style={{paddingTop: '6em'}}>
            {orgContext.loading ? <LoadingPage/> : props.children}
        </Container>
    </>
}