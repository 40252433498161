import {createContext, PropsWithChildren} from "react";
import textResources from "@ametektci/ametek.stcappscommon/src/language/TextResources";
import {textResources as localeStore} from "../language/TextResources";

export type Translatable = keyof typeof localeStore.Strings | keyof typeof textResources.Strings
export interface LanguageContextTemplate {
    getString(key: Translatable) : string
}
export const LanguageContext = createContext<LanguageContextTemplate>({
    getString(key: Translatable) {return "NoTranslator "+key}
})

export function LanguageContextWrapper(props: PropsWithChildren) {
    const searchables : Array<{[key: string]:string}> = [
        localeStore.Strings,
        textResources.Strings,
    ]
    const getString = (key: Translatable) => {
        for (let i = 0; i < searchables.length; i++)
        {
            let found = searchables[i][key]
            if (found != null)
                return found
        }
        return "NOT TRANSLATED "+key;
    }
    return (
        <LanguageContext.Provider value={
            {
                getString
            }
        } >{props.children}</LanguageContext.Provider>
    )
}