import {Button, Card, Container, Header, Label} from 'semantic-ui-react'
import {GetCreditCardIcon} from "../utils/CurrencyUtils";
import {CapitalizeFirstLetter} from "../utils/WindowUtils";
import UpdateCardModal from "./UpdateCardModal";
import {textResources} from "../language/TextResources";
import {CreditCardInfo} from "@ametektci/ametek.stcappscommon";
import {useContext, useState} from "react";
import {SubscriptionsContext} from "../contexts/SubscriptionsContext";
export interface PaymentCardProps {
    card: CreditCardInfo
}
export default function PaymentCard(props: PaymentCardProps) {
    const subsContext = useContext(SubscriptionsContext)
    const [defaultLoading, setDefaultLoading] = useState(false)
    const setCardDefault = () => {
        setDefaultLoading(true)

        subsContext
            .setCardAsDefault(props.card.creditCard.cardLastFourDigits, props.card.creditCard.cardType)
            .then(() => {
                setDefaultLoading(false)
            });
    };

    return (
                <Card>
                    <Card.Content>

                        <Header icon={GetCreditCardIcon(props.card.creditCard.cardType)} color='blue'
                                content={`${props.card.billingContactInfo.firstName} ${props.card.billingContactInfo.lastName}`} />

                        <Label content={CapitalizeFirstLetter(props.card.creditCard.cardType)} />

                        <br/>
                        <br/>
                        <Container>**** **** **** {props.card.creditCard.cardLastFourDigits}</Container>

                    </Card.Content>
                    <Card.Content extra textAlign='right'>
                        <Button 
                            content={props.card.isDefaultCard? textResources.Strings.default : textResources.Strings.setCardAsDefault} 
                            onClick={setCardDefault}
                            disabled={defaultLoading || props.card.isDefaultCard} 
                            loading={defaultLoading} 
                            color={'olive'} 
                            size='tiny'
                        />
                        <UpdateCardModal card={props.card}/>
                    </Card.Content>
                </Card>
        )
}
