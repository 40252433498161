import {useContext, useEffect} from 'react';
import FullAccess from "./navigation/FullAccess";
import ProfilePanel from "./profile/ProfilePanel";
import {MessageFeed, authenticationStore} from "@ametektci/ametek.stcappscommon"
import {OrganizationContext} from "./contexts/OrganizationContext";
import {Helmet} from "react-helmet-async";
import {APIContext} from "./contexts/APIContext";
import {
    createBrowserRouter,
    RouterProvider,
    Navigate,
    RouteObject
} from "react-router-dom";
import {JoinOrg} from "./JoinOrganization/JoinOrg";
import {VerifySwitch} from "./JoinOrganization/VerifySwitch";
//import {CreateOrg} from "./JoinOrganization/CreateOrg";
import UsersPanel from "./users/UsersPanel";
import {GaugesContextWrapper} from "./contexts/GaugesContext";
import {SubscriptionsContextWrapper} from "./contexts/SubscriptionsContext";
import BillingPanel from "./billing/BillingPanel";
import SubscriptionBills from "./billing/SubscriptionBills";
import {PaymentHistory} from "./billing/PaymentHistory";
import PaymentMethods from "./billing/PaymentMethods";
import CookieConsent from "./utils/CookieConsent";
import {NewUser} from "./JoinOrganization/NewUser";
import {WithLayout} from "./WithLayout";

export default function App() {
    const bluesnapLink = useContext(APIContext).blueSnapLink
    const orgContext = useContext(OrganizationContext)
    useEffect(() => {
        authenticationStore.refreshState()
        CookieConsent()
    }, [])

    let availablePages: RouteObject[]

    if (orgContext.organization == undefined && !orgContext.loading)
        availablePages = [
            {
                path: "*",
                index: true,
                element: <Navigate to={"/join"}/>
            },
            {
                path: "",
                element: <Navigate to={"/join"}/>
            },
        ]
    else {
        availablePages = [
            {
                path: "Profile",
                element: <ProfilePanel/>
            },
            {
                path: "",
                element: <Navigate to={"/Profile"}/>
            },
            {
                path: "*",
                element: <Navigate to={"/Profile"}/>
            }
        ]
        if (orgContext.user?.isAdmin) {
            availablePages = availablePages.concat([
                {
                    path: "users",
                    element: <UsersPanel/>
                },
                {
                    path: "billing",
                    element: <GaugesContextWrapper><SubscriptionsContextWrapper>
                        <BillingPanel/></SubscriptionsContextWrapper></GaugesContextWrapper>,
                    children: [
                        {
                            path: "",
                            element: <Navigate to={"PaymentMethod"}/>
                        },
                        {
                            path: "*",
                            element: <Navigate to={"PaymentMethod"}/>
                        },
                        {
                            path: "ActiveSubscriptions",
                            element: <SubscriptionBills/>
                        },
                        {
                            path: "History",
                            element: <PaymentHistory/>
                        },
                        {
                            path: "PaymentMethod",
                            element: <PaymentMethods/>
                        }
                    ]
                },
            ])
        }
    }
    let router = createBrowserRouter([
        {
            path: "/",
            element: <WithLayout><FullAccess/></WithLayout>,
            children: availablePages
        },
        {
            path: "/join",
            element: <WithLayout><VerifySwitch><NewUser/></VerifySwitch></WithLayout>
        },
        {
            path: "/join/:code",
            element: <WithLayout><VerifySwitch><JoinOrg/></VerifySwitch></WithLayout>
        }
    ])
    return (
        <div className="App">
            <Helmet>
                {bluesnapLink && orgContext.user?.isAdmin ?
                    <script type="text/javascript" src={bluesnapLink + "/web-sdk/4/bluesnap.js"}></script>
                    : null}
            </Helmet>
            <RouterProvider router={router}/>
            <MessageFeed/>
        </div>
    );

}
