import {useContext, useEffect, useState} from 'react';
import {Button, Divider, Form, Message, Modal} from 'semantic-ui-react';
import {textResources} from "../language/TextResources";
import {authenticationStore, messageQueue} from "@ametektci/ametek.stcappscommon"
import {OrganizationContext} from "../contexts/OrganizationContext";

export interface UserProps {
    userEmail: string
}

export default function ResetPassword (userProps:UserProps) {
    const orgContext = useContext(OrganizationContext)
    const [visible, setVisible] = useState(false)
    const [loading,setLoading] = useState(false)
    const [oldPassword,setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [newPasswordConfirm,setNewPasswordConfirm] = useState('')
    const [valid, setValid] = useState(false)
    const [error, setError] = useState<string | null>(null)
    useEffect(() => {
        checkValidInputs()
    }, [newPassword,newPasswordConfirm,oldPassword])
    const open = () => {
        setVisible(true)
        setOldPassword('')
        setNewPassword('')
        setNewPasswordConfirm('')
        setValid(false)
        setError(null)
    };

    const close = () => {
        if ( ! loading) {
            setVisible(false)
        }
    };

    const checkValidInputs = () => {
        let valid = true;
        let error = null;

        if (oldPassword.length < 8 ||
            newPassword.length < 8 ||
            newPasswordConfirm.length < 8) {
            valid = false;
        }
        else if (newPassword !== newPasswordConfirm) {
            valid = false;
            error = textResources.Strings.passwordsDoNotMatch;
        }
        else if ( ! authenticationStore.isValidPassword(newPassword)) {
            valid = false;
            error = textResources.Strings.passwordDoesNotMeetRequirements;
        }
        setValid(valid)
        setError(error)
    };

    const changePassword = () => {

        setLoading(true)

        authenticationStore.once('passwordChangedEvent', (error) => {
            if ( ! error) messageQueue.sendSuccess(textResources.Strings.passwordSuccessfullyChanged);
            setLoading(false)
            setError(error)
            setVisible(!!error)

            //send email saying password was changed
            orgContext.notifyUserOfPasswordChange(userProps.userEmail)
        });

        authenticationStore.changePassword(oldPassword, newPassword);
    };

        return (
            <Modal size='tiny' open={visible}
                   trigger={<Button content={textResources.Strings.changePassword} icon='lock' onClick={open}/>} >
                <Modal.Header>{textResources.Strings.changePassword}</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Form>

                            <Form.Input placeholder={textResources.Strings.currentPassword} label={textResources.Strings.currentPassword} fluid
                                        name='oldPassword' onChange={(e, {value}) => setOldPassword(value)} type='password'/>

                            <Divider/>

                            <Message content={textResources.Strings.passwordRequirements} />

                            <Form.Input placeholder={textResources.Strings.newPassword} label={textResources.Strings.newPassword} fluid
                                        name='newPassword' onChange={(e, {value}) => setNewPassword(value)} type='password'/>

                            <Form.Input placeholder={textResources.Strings.newPasswordConfirm} label={textResources.Strings.newPasswordConfirm} fluid
                                        name='newPasswordConfirm' onChange={(e, {value}) => setNewPasswordConfirm(value)} type='password'/>
                        </Form>

                        <Message error content={error} hidden={!error}/>

                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={close} disabled={loading} content={textResources.Strings.cancel} />
                    <Button positive disabled={loading || !valid}
                            loading={loading} onClick={changePassword} content={textResources.Strings.changePassword} />
                </Modal.Actions>
            </Modal>
        )
}
