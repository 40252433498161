import {useContext, useState} from 'react';
import {Button, Header, Modal} from 'semantic-ui-react';
import {textResources} from "../language/TextResources";
import {User} from "../Models/Types/User";
import {OrganizationContext} from "../contexts/OrganizationContext";
export interface RemoveUserProps {
    user: User
}
export default function RemoveUser (props: RemoveUserProps) {
    const orgContext = useContext(OrganizationContext)
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)

    const open = () => {
        setVisible(true)
    };
    const close = () => {
        if ( ! loading) {
            setVisible(false)
        }
    }

    const removeUser = () => {
        setLoading(true)

        orgContext.removeUser(props.user.email).then(() => {
            setLoading(false)
            setVisible(false)
        });
    }

        return (
            <Modal size='tiny' open={visible}
                   trigger={<Button color='red' size={'tiny'} content={textResources.Strings.remove} onClick={open}/>} >
                <Modal.Header>{textResources.Strings.confirmRemoveUser}</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Header content={props.user.name} subheader={props.user.isAdmin ? textResources.Strings.admin : textResources.Strings.standardUser}/>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={close} disabled={loading} content={textResources.Strings.cancel} />
                    <Button negative disabled={loading}
                            loading={loading} onClick={removeUser} content={textResources.Strings.removeUser} />
                </Modal.Actions>
            </Modal>
        )
}