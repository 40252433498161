/**
 * @return {string}
 */
export const FormatCurrency = function (amount: number) {
    return "$" + amount.toFixed(2);
};

/**
 * @return {string}
 */
export const GetCreditCardIcon = function (issuer : string) {
    switch (issuer) {
        case "visa":
            return 'cc visa';
        case "discover":
            return 'cc discover';
        case "mastercard":
            return 'cc mastercard';
        default:
            return 'credit card';
    }
};
