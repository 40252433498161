import {useContext} from 'react'
import {Card, Grid, Header, Segment} from 'semantic-ui-react'
import PaymentCard from "./PaymentCard";
import AddNewCardModal from "./AddNewCardModal";
import {textResources} from "../language/TextResources";
import {SubscriptionsContext} from "../contexts/SubscriptionsContext";
import {ShopperInfo} from "./ShopperInfo";

export default function PaymentMethods() {
    const subscriptionContext = useContext(SubscriptionsContext)
    const paymentCards = subscriptionContext.shopper?.paymentSources.creditCardInfo ?? []

    return (
        <Segment>
            <Header size='large' content={textResources.Strings.paymentMethods} dividing/>

            <Grid>
                <Grid.Column width={12}>
                    <Header size='small' color='blue' content={textResources.Strings.cardsOnFile} dividing/>

                    {paymentCards.length === 0 ?
                        <span>
                            <Segment textAlign='center' size='tiny'>
                                <Header content={textResources.Strings.noCardsOnFile} disabled/>
                            </Segment>
                            <br/>
                        </span>
                        : null
                    }

                    <Card.Group itemsPerRow={2}>
                        {paymentCards.map(card => (
                            <PaymentCard card={card} key={card.creditCard.cardLastFourDigits}/>
                        ))}
                    </Card.Group>
                </Grid.Column>
                <Grid.Column width={4}>
                    <Header size='small' color='green' content={textResources.Strings.addPaymentCard} dividing/>

                    <AddNewCardModal/>
                </Grid.Column>
            </Grid>
            <br /> &nbsp;
            <ShopperInfo/>
        </Segment>
    )
}
