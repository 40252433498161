import {CreditCardInfo} from "@ametektci/ametek.stcappscommon";

export interface BlueSnapShopper {
    vaultedShopperId?: number,
    firstName: string,
    lastName : string,
    country: string,
    state?: string,
    city: string,
    address: string,
    address2: string,
    email: string,
    zip: string,
    phone: string,
    companyName: string,
    shopperCurrency: string,
    paymentSources: {
        creditCardInfo: CreditCardInfo[]
    }
    shippingContactInfo:ShippingContactInfo
}
export interface ShippingContactInfo{
    firstName: string,
    lastName : string,
    country: string,
    state?: string,
    city: string,
    address1: string,
    address2: string,
    zip:string,
}
export const DefaultShopper : BlueSnapShopper = {
    email: "",
    firstName: "",
    lastName: "",
    country: "us",
    city: "",
    address: "",
    address2: "",
    zip:"",
    phone: "",
    companyName: "",
    shopperCurrency: "USD",
    paymentSources: {
        creditCardInfo: []
    },
    shippingContactInfo: {
        firstName:"",
        lastName : "",
        country: "us",
        state: "",
        city: "",
        address1:"",
        address2: "",
        zip:"",
    },
}