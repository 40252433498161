export const UpdateUrlParams = function (urlSearch: string) {
    if (urlSearch === '') {
        urlSearch = '/';
    }
    window.history.pushState({}, document.title, urlSearch);
};

export const ReloadPage = function () {
    window.location.reload();
};

/**
 * @return {string}
 */
export const CapitalizeFirstLetter = function (string : string) {
    return !string || string.length === 0
        ? ""
        : string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};
