import {Header, Message, Segment} from "semantic-ui-react";
import {useContext} from "react";
import {LanguageContext} from "../contexts/LanguageContext";

export function UseInvite()
{
    const translate = useContext(LanguageContext)
    return (<Segment>
        <Header>{translate.getString("joinExistingOrganization")}</Header>
        <Message content={translate.getString("attemptToJoinExisting")} />
    </Segment>)
}