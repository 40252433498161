import {Menu} from "semantic-ui-react";
import {useContext, useState} from "react";
import {LanguageContext} from "../contexts/LanguageContext";
import {CreateOrganization} from "./CreateOrganization";
import {UseInvite} from "./UseInvite";

export function NewUser() {
    const translate = useContext(LanguageContext)
    const [option, setOption] = useState("create")
    return (
        <div>
            <Menu pointing secondary>
                <Menu.Item
                    content={translate.getString("createNewOrganization")} 
                    onClick={() => setOption("create")} 
                    active={option == "create"}
                />
                <Menu.Item
                    content={translate.getString("joinExistingOrganization")}
                    onClick={() => setOption("join")}
                    active={option == "join"}
                />
            </Menu>
            {option == "join"? <UseInvite/> : <CreateOrganization/>}
            
        </div>
    )
}