import {Button, Header, Icon, Label, Menu, Modal} from "semantic-ui-react";
import {useContext, useState} from "react";
import {LanguageContext} from "../contexts/LanguageContext";
import {AppVersion} from "../utils/AppVersion";
import {APIContext} from "../contexts/APIContext";
import {GetEnvironmentSetting} from "../utils/EnvironmentSettings";
import {messageQueue} from "@ametektci/ametek.stcappscommon";

export function ConnectionInfo() {
    const translate = useContext(LanguageContext)
    const api = useContext(APIContext)
    const [open, setOpen] = useState(false)

    const entries = [
        {label: translate.getString("siteVersion"), value: AppVersion},
        {label: translate.getString("apiVersion"), value: api.appVersion},
    ]
    const copySiteInformation = () => {
        navigator.clipboard.writeText(
            entries.map(e => e.label + ": " + e.value).join("\n")
        ).then(() => messageQueue.sendSuccess(translate.getString("successfullyCopied")))
            .catch(() => messageQueue.sendError(translate.getString("unableToCopy")))
    }

    return <Modal size={"small"} trigger={
        <Menu.Item>
            <Icon name={"question circle"}/>
            {translate.getString("supportButton")}
        </Menu.Item>
    } position={"bottom right"} open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)}>
        <Modal.Header>
            <Header>{translate.getString("connectionInformation")}</Header>
        </Modal.Header>
        <Modal.Content>
            <Header dividing>
                {translate.getString("documentation")}
            </Header>
            <a href={GetEnvironmentSetting('documentationUrl') as string} target={"_blank"}>{translate.getString("documentationLink")}</a>
            <Header dividing>
                {translate.getString("contactUs")}
            </Header>
            <a href={"https://www.ametekcalibration.com/contact/contactametektci"}
               target={"_blank"}>{translate.getString("contactAmetekLink")}</a>
            
            <Header>
                {translate.getString("siteInformation")} &nbsp;
                <Button style={{"box-shadow": "none", "padding-right": ".25em"}} basic onClick={copySiteInformation} icon={<Icon name={"copy outline"} size={"mini"} />} />
            </Header>
            {entries.map(e =>
                <div key={e.label}>
                    <Label>{e.label}:</Label>&nbsp;
                    <span>{e.value}</span>
                </div>
            )}
        </Modal.Content>
        <Modal.Actions>
            <Button color={"red"} onClick={() => setOpen(false)}>{translate.getString("close")}</Button>
        </Modal.Actions>
    </Modal>
}