import {useContext} from 'react'
import {Card, Container, Header, Segment, Table} from 'semantic-ui-react'
import {FormatCurrency} from "../utils/CurrencyUtils";
import {textResources} from "../language/TextResources";
import CancelSubscriptionModal from "./CancelSubscriptionModal";
import {DateUtils} from '@ametektci/ametek.stcappscommon';
import {OrganizationContext} from "../contexts/OrganizationContext";
import {LanguageContext} from "../contexts/LanguageContext";

export default function SubscriptionBills() {
    const translate = useContext(LanguageContext)
    const orgContext = useContext(OrganizationContext)
    const billingItems = orgContext.billingItems
    let set = new Set(billingItems.map(b => b.product))
    const tiers = Array.from(set)
    const seats = orgContext.getOrganizationSeats()
    const getSerial = (bluesnapSubscription?: number): string => {
        if (!bluesnapSubscription || bluesnapSubscription == 0)
            return textResources.Strings.unknown
        else {
            let relevantSeats = seats?.filter(s => s.blueSnapSubscriptionId == bluesnapSubscription && s.serialNumber).map(s => s.serialNumber)
            if (!relevantSeats || (relevantSeats.length == 0)) {
                return textResources.Strings.unknown
            }
            return relevantSeats!.join(", ")
        }
    }

    return (
        <Segment>
            <Header size='large' content={translate.getString("activeSubscriptions")} dividing/>

            {billingItems.length === 0 ?
                <span>
                        <Segment textAlign='center' size='tiny'>
                            <Header content={textResources.Strings.noActiveInvoices} disabled/>
                        </Segment>
                        <br/>
                    </span>
                : null
            }
            {tiers.map(t =>
                <div key={t}>
                    <Header size={"medium"} dividing={true} content={t}/>
                    <Card.Group itemsPerRow={3} stackable={true}>
                        {billingItems.filter(bi => bi.product == t).map(billingItem => (
                            <Card key={billingItem.subscriptionId}>
                                <Card.Content>
                                    <Card.Header
                                        content={textResources.Strings.forGauge.replace("###", getSerial(billingItem.subscriptionId))}/>
                                    <Header content={textResources.Strings.pricing} dividing color='blue' size='tiny'/>

                                    <Table textAlign='right'>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>{textResources.Strings.quantity}</Table.HeaderCell>
                                                <Table.HeaderCell>{textResources.Strings.price}</Table.HeaderCell>
                                                <Table.HeaderCell>{textResources.Strings.frequency}</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>

                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell content={billingItem.quantity}/>
                                                <Table.Cell content={FormatCurrency(billingItem.chargeAmount)}/>
                                                <Table.Cell content={billingItem.chargeFrequency}/>
                                            </Table.Row>
                                        </Table.Body>

                                        <Table.Footer>
                                            <Table.Row>
                                                <Table.HeaderCell content={textResources.Strings.total}/>
                                                <Table.HeaderCell
                                                    content={FormatCurrency(billingItem.quantity * billingItem.chargeAmount)}/>
                                                <Table.HeaderCell/>
                                            </Table.Row>
                                        </Table.Footer>

                                    </Table>

                                    <Header content={textResources.Strings.nextInvoiceDate} dividing color='blue'
                                            size='tiny'/>

                                    <Container content={DateUtils.FormatDate(billingItem.nextBillingDate)}/>

                                    <Header content={textResources.Strings.actions} dividing color='blue' size='tiny'/>

                                    <CancelSubscriptionModal subscription={billingItem}/>

                                </Card.Content>
                            </Card>
                        ))}
                    </Card.Group>
                    {/* The second instance of the header was squished. This empty line helps with that. */}
                    <div>&nbsp;</div>
                </div>
            )}

        </Segment>
    )
}
